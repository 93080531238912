import type * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { Link } from "@remix-run/react";
import React, { useId, useRef } from "react";
import { useInputEvent } from "@conform-to/react";
import { Checkbox } from "~/components/ui/Checkbox";
import { cn } from "../../utils/misc";

export type ListOfErrors = Array<string | null | undefined> | null | undefined;

export function ErrorList({
  id,
  errors,
}: {
  errors?: ListOfErrors;
  id?: string;
}) {
  const errorsToRender = errors?.filter(Boolean);
  if (!errorsToRender?.length) return null;
  return (
    <ul id={id} className="space-y-1">
      {errorsToRender.map((e) => (
        <li key={e} className="text-xs text-red-500">
          {e}
        </li>
      ))}
    </ul>
  );
}

export function Field({
  labelProps,
  inputProps,
  errors,
  className,
  descriptor,
}: {
  labelProps: Omit<JSX.IntrinsicElements["label"], "className">;
  inputProps: Omit<JSX.IntrinsicElements["input"], "className">;
  errors?: ListOfErrors;
  className?: React.HTMLAttributes<HTMLDivElement>["className"];
  descriptor?: string;
}) {
  const fallbackId = useId();
  const id = inputProps.id ?? fallbackId;
  const errorId = errors?.length ? `${id}-error` : undefined;
  const defaultClasses = "space-y-2";

  return (
    <div className={cn(defaultClasses, className)}>
      <label
        htmlFor={id}
        {...labelProps}
        className="block text-sm font-medium text-secondary-foreground"
      />
      <input
        id={id}
        aria-invalid={errorId ? true : undefined}
        aria-describedby={errorId}
        placeholder=" "
        {...inputProps}
        className="flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
      />
      {descriptor && (
        <p className="text-[0.8rem] text-muted-foreground">{descriptor}</p>
      )}
      {/* the label comes after the input so we can use the sibling selector in the CSS to give us animated label control in CSS only */}
      {errorId ? <ErrorList id={errorId} errors={errors} /> : null}
    </div>
  );
}

export function TextareaField({
  labelProps,
  textareaProps,
  errors,
  className,
}: {
  labelProps: Omit<JSX.IntrinsicElements["label"], "className">;
  textareaProps: Omit<JSX.IntrinsicElements["textarea"], "className">;
  errors?: ListOfErrors;
  className?: React.HTMLAttributes<HTMLDivElement>["className"];
}) {
  const fallbackId = useId();
  const id = textareaProps.id ?? textareaProps.name ?? fallbackId;
  const errorId = errors?.length ? `${id}-error` : undefined;
  return (
    <div className={className}>
      <textarea
        id={id}
        aria-invalid={errorId ? true : undefined}
        aria-describedby={errorId}
        placeholder=" "
        {...textareaProps}
        className="focus:border-accent-purple border-night-400 bg-night-700 text-body-xs disabled:bg-night-400 h-48 w-full rounded-lg border px-4 pt-8 caret-white outline-none"
      />
      {/* the label comes after the input so we can use the sibling selector in the CSS to give us animated label control in CSS only */}
      <label htmlFor={id} {...labelProps} />
      <div className="px-4 pb-3 pt-1">
        {errorId ? <ErrorList id={errorId} errors={errors} /> : null}
      </div>
    </div>
  );
}

export type CheckboxProps = Omit<
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>,
  "type"
> & {
  type?: string;
};

export function CheckboxField({
  labelProps,
  buttonProps,
  errors,
  className,
}: {
  labelProps: JSX.IntrinsicElements["label"];
  buttonProps: CheckboxProps;
  errors?: ListOfErrors;
  className?: React.HTMLAttributes<HTMLDivElement>["className"];
}) {
  const fallbackId = useId();
  const buttonRef = useRef<HTMLButtonElement>(null);
  // To emulate native events that Conform listen to:
  // See https://conform.guide/integrations
  const control = useInputEvent({
    // Retrieve the checkbox element by name instead as Radix does not expose the internal checkbox element
    // See https://github.com/radix-ui/primitives/discussions/874
    ref: () =>
      buttonRef.current?.form?.elements.namedItem(buttonProps.name ?? ""),
    onFocus: () => buttonRef.current?.focus(),
  });
  const id = buttonProps.id ?? buttonProps.name ?? fallbackId;
  const errorId = errors?.length ? `${id}-error` : undefined;
  return (
    <div className={className}>
      <div className="flex items-center gap-2">
        <Checkbox
          id={id}
          ref={buttonRef}
          aria-invalid={errorId ? true : undefined}
          aria-describedby={errorId}
          {...buttonProps}
          onCheckedChange={(state) => {
            control.change(Boolean(state.valueOf()));
            buttonProps.onCheckedChange?.(state);
          }}
          onFocus={(event) => {
            control.focus();
            buttonProps.onFocus?.(event);
          }}
          onBlur={(event) => {
            control.blur();
            buttonProps.onBlur?.(event);
          }}
          type="button"
        />
        <label
          htmlFor={id}
          {...labelProps}
          className="text-body-xs self-center text-muted-foreground"
        />
      </div>
      {errorId ? <ErrorList id={errorId} errors={errors} /> : null}
    </div>
  );
}

export function getButtonClassName({
  size,
  variant,
}: {
  size: "xs" | "sm" | "md" | "md-wide" | "pill";
  variant: "primary" | "secondary";
}) {
  const baseClassName =
    "flex justify-center items-center rounded-full font-bold outline-none transition-[background-color,color] duration-200 disabled:bg-night-500 disabled:text-night-200";
  // Kaitie - changed this primary to our most used button style
  const primaryClassName =
    "relative inline-flex items-center rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-800";
  const secondaryClassName =
    "border-[1.5px] border-night-400 bg-night-700 hover:border-accent-purple focus:border-accent-purple active:border-accent-purple-lighter";
  const extraSmallClassName = "py-2 px-3 text-body-xs";
  const smallClassName = "";
  const mediumClassName = "px-14 py-5 text-lg";
  const mediumWideClassName = "px-24 py-5 text-lg";
  const pillClassName = "px-12 py-3 leading-3";
  const className = cn(baseClassName, {
    [primaryClassName]: variant === "primary",
    [secondaryClassName]: variant === "secondary",
    [extraSmallClassName]: size === "xs",
    [smallClassName]: size === "sm",
    [mediumClassName]: size === "md",
    [mediumWideClassName]: size === "md-wide",
    [pillClassName]: size === "pill",
  });
  return className;
}

export function Button({
  size,
  variant,
  status = "idle",
  ...props
}: React.ComponentPropsWithoutRef<"button"> &
  Parameters<typeof getButtonClassName>[0] & {
    status?: "pending" | "success" | "error" | "idle";
  }) {
  const companion = {
    pending: <span className="inline-block animate-spin">🌀</span>,
    success: <span>✅</span>,
    error: <span>❌</span>,
    idle: null,
  }[status];
  return (
    <button
      {...props}
      className={cn(
        props.className,
        getButtonClassName({ size, variant }),
        "flex justify-center gap-4",
      )}
    >
      <div>{props.children}</div>
      {companion}
    </button>
  );
}

export function ButtonLink({
  size,
  variant,
  ...props
}: Omit<React.ComponentPropsWithoutRef<typeof Link>, "className"> &
  Parameters<typeof getButtonClassName>[0]) {
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  return <Link {...props} className={getButtonClassName({ size, variant })} />;
}

export function LabelButton({
  size,
  variant,
  ...props
}: Omit<React.ComponentPropsWithoutRef<"label">, "className"> &
  Parameters<typeof getButtonClassName>[0]) {
  return (
    <label
      {...props}
      className={cn("cursor-pointer", getButtonClassName({ size, variant }))}
    />
  );
}
